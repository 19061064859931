.printDoc {
    font-family: 'Open Sans', sans-serif;
    width: '595pt';
    font-size: 14px;
    margin-left: auto;
    margin-right: auto;
    /* border: 1px solid #333; */
    background: #fff;
    margin-top: 20px;
    margin-bottom: 0;
    padding: 0;
}

.s1 {
    color: #666;
    font-family: "Trebuchet MS", sans-serif;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 10pt;
}

.s2 {
    color: #666;
    font-family: "Trebuchet MS", sans-serif;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 7pt;
}

.s3 {
    color: #666;
    font-family: "Trebuchet MS", sans-serif;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 9pt;
}

li {
    display: block;
}

#l1 {
    padding-left: 0pt;
    counter-reset: c1 1;
}

#l1>li>*:first-child:before {
    counter-increment: c1;
    content: counter(c1, decimal)". ";
    color: #666;
    font-family: "Trebuchet MS", sans-serif;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 10pt;
}

#l1>li:first-child>*:first-child:before {
    counter-increment: c1 0;
}