.gstr3b-blank {
    background-color: #eee;
}

.newinv {
    width: 140px;
    min-width: 100%
}

.uline {
    padding: 1em;
    margin-bottom: 16px;
}

.total {
    width: 200px !important;
}

.has-error .form-control {
    color: #a94442 !important;
}

.head-90 {
    width: 90%;
}

.rowPadding {
    padding: 4px 8px !important;
}

.card-error {
    color: #fff !important;
    background-color: #d84d4b !important;
}