.impdates .dates-list li .square-box.b5 {
    background: #18436b;
    width: 120px;
}

.impdates .dates-list li .square-box.b6 {
    background: #6474a0;
    width: 230px;
}

@media screen and (min-width: 390px) and (max-width: 1200px) {
    .impdates .dates-list li {
        width: 100% !important;
    }

    .impdates .dates-list li .square-box.b1 {
        width: 100%;
    }

    .impdates .dates-list li .square-box.b2 {
        width: 100%;
    }

    .impdates .dates-list li .square-box.b3 {
        width: 100%;
    }

    .impdates .dates-list li .square-box.b4 {
        width: 100%;
    }
}

@media screen and (min-width: 320px) and (max-width: 1200px) {
    .impdates .dates-list li .square-box.b5 {
        width: 100%;
    }

    .impdates .dates-list li .square-box.b6 {
        width: 100%;
    }
}

.impdates .dates-list li .square-box {
    display: inline-block;
    float: left;
    width: 174px;
    height: 32px;
}

.impdates .dates-list li .square-content {
    font-size: 12px;
    font-weight: 600;
}

.haeding {
    font-size: 12px;
    font-weight: bold;
}

.headimp {
    margin-bottom: 19px;
}

.impdates .dates-list li {
    width: 49%;
    display: inline-block;
    background: #ddd;
    font-size: 14px;
    padding: 0px;
    font-family: tahoma;
    margin-top: -6px;
    border: 1px solid #4f4d498c;
}

.impdates .dates-list li .square-box.b1 {
    background: #1d867a;
}

.imp {
    font-weight: 600;
    font-size: 11px;
    text-align: center;
    margin-top: 7px;
}

.fa-info-circle {
    background: #fcf8e3;
    color: #1f1e1ecc;
    padding-left: 0px;
}

.rfd {
    font-size: 8px;
}

.refd {
    height: 35px;
}

.rfdtene {
    margin-left: -5px;
    font-size: -4px;
}

.eightenMonths {
    font-size: 6px;
    font-weight: 600;
}

.refundrfd {
    font-size: 8px;
}

.headoth {
    font-size: 11px;
    font-weight: 600;
    margin-bottom: 11px;
}

.fa-info-circle:before {
    content: "";
    background: #fff;
    color: #423838;
    font-size: 12px;
}

@media screen and (max-width: 768px) and (min-width: 320px) {
    .impdates .dates-list li .square-box {
        width: 141px;
    }
}

@media screen and (max-width: 1199px) {
    .impdates .dates-list li .square-content {
        margin-top: 7px;
        font-size: 10px;
    }
}

@media screen and (max-width: 768px) {
    .refd {
        margin-top: 0px;
    }

    ul.lst>li>p:last-child {
        font-size: 8px;
        height: 2px;
    }
}

ul.lst>li>p:last-child {
    font-size: 8px;
    height: 2px;
}

@media screen and (max-width: 768px) and (min-width: 360px) {
    .impdates .dates-list li .square-box {
        width: 161px;
    }
}

@media screen and (max-width: 768px) and (min-width: 375px) {
    .impdates .dates-list li .square-box {
        width: 168px;
    }
}