.printDoc {
    font-family: 'Open Sans', sans-serif;
    width: '595pt';
    font-size: 14px;
    margin-left: auto;
    margin-right: auto;
    /* border: 1px solid #333; */
    background: #fff;
    margin-top: 20px;
    margin-bottom: 0;
    padding-top: 0;
    padding-bottom: 0;
    padding-left: '5pt';
    padding-right: '5pt';
}

.printDoc * {
    margin: 0;
    padding: 0;
    text-indent: 0;
}

.sh {
    color: black;
    font-family: "Trebuchet MS", sans-serif;
    font-weight: bold;
    text-decoration: none;
    font-size: 13px !important;
}

.s1 {
    color: black;
    font-family: "Trebuchet MS", sans-serif;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 18pt;
}

.s2 {
    color: black;
    font-family: "Trebuchet MS", sans-serif;
    font-style: italic;
    font-weight: normal;
    text-decoration: none;
    font-size: 12pt;
}

.s2 {
    color: black;
    font-family: "Trebuchet MS", sans-serif;
    font-style: italic;
    font-weight: normal;
    text-decoration: none;
    font-size: 12pt;
}

.s3 {
    color: black;
    font-family: "Trebuchet MS", sans-serif;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 9pt;
}

.s4 {
    color: black;
    font-family: "Trebuchet MS", sans-serif;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 8pt;
}

.s5 {
    color: black;
    font-family: "Trebuchet MS", sans-serif;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 8pt;
}

.s6 {
    color: #808080;
    font-family: "Trebuchet MS", sans-serif;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 150pt;
    vertical-align: -3pt;
}

.s12 {
    color: black;
    font-family: "Times New Roman", serif;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 10pt;
}

.s13 {
    color: black;
    font-family: "Trebuchet MS", sans-serif;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 10pt;
}