.alink {
    color: black;
    text-decoration: none;
    cursor: default;
}

.alink:hover {
    color: black;
    text-decoration: none;
    cursor: default;
}