@media only screen and (min-width: 768px) {
    .slide {
        height: 200px;
    }
}

@media only screen and (min-width: 992px) {
    .slide {
        height: 320px;
    }
}

@media only screen and (min-width: 1200px) {
    .slide {
        height: 520px;
    }
}