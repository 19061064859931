.printDoc {
    font-family: 'Open Sans', sans-serif;
    width: 1080px;
    font-size: 14px;
    margin-left: auto;
    margin-right: auto;
    /* border: 1px solid #333; */
    background: #fff;
    margin-top: 20px;
    margin-bottom: 0;
    padding: 0;
}

.printDoc * {
    margin: 0;
    padding: 0;
    text-indent: 0;
}

.s1 {
    color: black;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 800;
    text-decoration: none;
    font-size: 12pt;
}

.s4 {
    color: black;
    font-family: "Trebuchet MS", sans-serif;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 7.5pt;
    line-height: 0.5pt;
}

.s4b {
    color: black;
    font-family: "Trebuchet MS", sans-serif;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 7.5pt;
}

.s5 {
    color: #FFF;
    font-family: "Trebuchet MS", sans-serif;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 9pt;
}

.s5b {
    color: #1C74AF;
    font-family: "Trebuchet MS", sans-serif;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 7.5pt;
    line-height: 0.5pt;
}

.s6 {
    color: #002060;
    font-family: "Trebuchet MS", sans-serif;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 7.5pt;
}

/*.para {
    color: black;
    font-family: "Trebuchet MS", sans-serif;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 9pt;
    margin: 0pt;
}

.s2 {
    color: black;
    font-family: "Trebuchet MS", sans-serif;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 220pt;
}

.s3 {
    color: black;
    font-family: "Trebuchet MS", sans-serif;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 220pt;
    vertical-align: 1pt;
}



.padding {
    padding-left: 5px;
    padding-right: 5px
}

*/