.antiqueWhite {
    background-color: #f9f9f9;
    color: #254475;
    font-weight: bold;
}

body {
    font-family: verdana, "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.table {

    overflow-x: auto;
    display: block;

}

.btn-new {
    color: #fff;
    background-color: #35be96;
    border-color: #35be96;
}

.btn-new:focus,
.btn-new.focus,
.btn-new:active:focus,
.btn-new:active.focus,
.btn-new.active:focus,
.btn-new.active.focus,
.btn-new.hover,
.btn-new:hover {
    outline: 0px;
    outline-offset: 0px;
    color: #fff;
    background-color: #35be96;
    border-color: #35be96;
}

.greyCell {
    background-color: rgba(171, 171, 173, 0.69) !important;
}

.subsec {
    padding-left: 4rem !important;
}

.sub_subsec {
    padding-left: 8rem !important;
}

.sub_sub_subsec {
    padding-left: 12rem !important;
}

.subHeading {
    color: #254475;
    /* font-weight: bold; */
}

.verticalCenter {
    vertical-align: middle !important;
}