* {
    box-shadow: border-box;
    margin: 0;
    padding: 0;
}

html {
    scroll-behaviour: smooth;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

.nShadow {
    box-shadow: 20px 20px 50px #ececec, -30px -30px 60px #f8f8f8;
}