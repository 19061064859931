.container[_ngcontent-gwq-c0] {
    min-height: 46px;
    padding-bottom: 20px
}

.mar-top-bot-100[_ngcontent-gwq-c0] {
    margin-top: 100px;
    margin-bottom: 100px
}

.modal-top-15[_ngcontent-gwq-c0] {
    top: 15% !important
}

*[_ngcontent-gwq-c0] {
    font-family: verdana, "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 14px;
    line-height: 1.428571429;
    color: #212121
}

.bread-trans[_ngcontent-gwq-c0] {
    background-color: transparent !important
}

.modal-right[_ngcontent-gwq-c0] {
    position: fixed;
    top: 60px;
    right: 0;
    z-index: 1050;
    width: 350px;
    overflow: hidden;
    outline: 0
}

.bg-none[_ngcontent-gwq-c0] {
    background-color: none !important
}

.loader[_ngcontent-gwq-c0] {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    opacity: .5;
    background: gray;
    display: none;
    z-index: 99
}

#dimmer[_ngcontent-gwq-c0] {
    position: absolute;
    margin: auto;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    width: 19px;
    height: 19px;
    border-radius: 100%;
    box-shadow: 19px 19px #0b1f59, -19px 19px #dfdfdf, -19px -19px #0b1f59, 19px -19px #dfdfdf;
    -o-box-shadow: 19px 19px #0b1f59, -19px 19px #dfdfdf, -19px -19px #0b1f59, 19px -19px #dfdfdf;
    -ms-box-shadow: 19px 19px #0b1f59, -19px 19px #dfdfdf, -19px -19px #0b1f59, 19px -19px #dfdfdf;
    -webkit-box-shadow: 19px 19px #0b1f59, -19px 19px #dfdfdf, -19px -19px #0b1f59, 19px -19px #dfdfdf;
    -moz-box-shadow: 19px 19px #0b1f59, -19px 19px #dfdfdf, -19px -19px #0b1f59, 19px -19px #dfdfdf;
    animation: 3.4s infinite cssload-spin;
    -o-animation: 3.4s infinite cssload-spin;
    -ms-animation: cssload-spin ease infinite 3.4s;
    -webkit-animation: 3.4s infinite cssload-spin;
    -moz-animation: 3.4s infinite cssload-spin
}

@-webkit-keyframes spin {
    0% {
        transform: rotate(0)
    }

    100% {
        transform: rotate(360deg)
    }
}

@keyframes spin {
    0% {
        transform: rotate(0)
    }

    100% {
        transform: rotate(360deg)
    }
}

.text-warn[_ngcontent-gwq-c0] {
    color: #f8bb86
}

.fade.in[_ngcontent-gwq-c0] {
    opacity: 1
}

.fade[_ngcontent-gwq-c0] {
    opacity: 0;
    transition: opacity .15s linear
}

.modal-footer[_ngcontent-gwq-c0] {
    border: none
}

.btn[_ngcontent-gwq-c0] {
    padding: 6px 25px;
    border-radius: 0
}

.btn-primary[_ngcontent-gwq-c0] {
    color: #fff;
    background-color: #2c4e86;
    border-color: #264373
}

.btn-primary.active[_ngcontent-gwq-c0],
.btn-primary[_ngcontent-gwq-c0]:active,
.open[_ngcontent-gwq-c0]>.btn-primary.dropdown-toggle[_ngcontent-gwq-c0] {
    color: #fff;
    background-color: #1f3860;
    border-color: #060b13
}

.modal-open[_ngcontent-gwq-c0] {
    overflow: hidden
}

.modal[_ngcontent-gwq-c0] {
    display: none;
    overflow: hidden;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1050;
    -webkit-overflow-scrolling: touch;
    outline: 0
}

.modal-dialog.sweet[_ngcontent-gwq-c0] .modal-content[_ngcontent-gwq-c0] .m-iconrd[_ngcontent-gwq-c0] {
    width: 60px;
    height: 60px;
    border: 4px solid #f27474;
    border-radius: 50%;
    margin: 20px auto;
    padding: 0;
    position: relative;
    box-sizing: content-box
}

.modal-dialog.sweet[_ngcontent-gwq-c0] .modal-content[_ngcontent-gwq-c0] .m-warnings[_ngcontent-gwq-c0] {
    border-color: #f27474
}

.modal-dialog.sweet[_ngcontent-gwq-c0] .modal-content[_ngcontent-gwq-c0] .m-warnings[_ngcontent-gwq-c0] .micon-body[_ngcontent-gwq-c0] {
    position: absolute;
    width: 5px;
    height: 30px;
    left: 50%;
    top: 10px;
    border-radius: 2px;
    margin-left: -2px;
    background-color: #f27474
}

.modal-dialog.sweet[_ngcontent-gwq-c0] .modal-content[_ngcontent-gwq-c0] .m-warnings[_ngcontent-gwq-c0] .micon-dot[_ngcontent-gwq-c0] {
    position: absolute;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    margin-left: -3px;
    left: 50%;
    bottom: 10px;
    background-color: #f27474
}

.modal.fade[_ngcontent-gwq-c0] .modal-dialog[_ngcontent-gwq-c0] {
    -ms-transform: translate(0, -25%);
    transform: translate(0, -25%);
    transition: transform .3s ease-out
}

.modal.in[_ngcontent-gwq-c0] .modal-dialog[_ngcontent-gwq-c0] {
    -ms-transform: translate(0, 0);
    transform: translate(0, 0)
}

.modal-open[_ngcontent-gwq-c0] .modal[_ngcontent-gwq-c0] {
    overflow-x: hidden;
    overflow-y: auto
}

.modal-dialog[_ngcontent-gwq-c0] {
    position: relative;
    width: auto;
    margin: 10px;
    z-index: 9999999999
}

.modal-dialog.sweet[_ngcontent-gwq-c0] .modal-content[_ngcontent-gwq-c0] h2[_ngcontent-gwq-c0] {
    color: #575757;
    font-size: 30px;
    text-align: center;
    font-weight: 600;
    text-transform: none;
    position: relative;
    margin: 10px 0;
    padding: 0;
    line-height: 40px;
    display: block
}

.modal-dialog.sweet[_ngcontent-gwq-c0] .modal-content[_ngcontent-gwq-c0] .m-icon[_ngcontent-gwq-c0] {
    width: 60px;
    height: 60px;
    border: 4px solid gray;
    border-radius: 50%;
    margin: 20px auto;
    padding: 0;
    position: relative;
    box-sizing: content-box
}

.modal-dialog.sweet[_ngcontent-gwq-c0] .modal-content[_ngcontent-gwq-c0] .m-warning[_ngcontent-gwq-c0] {
    border-color: #f0ad2e
}

.modal-dialog.sweet[_ngcontent-gwq-c0] .modal-content[_ngcontent-gwq-c0] .m-warning[_ngcontent-gwq-c0] .micon-body[_ngcontent-gwq-c0] {
    position: absolute;
    width: 5px;
    height: 30px;
    left: 50%;
    top: 10px;
    border-radius: 2px;
    margin-left: -2px;
    background-color: #f0ad2e
}

.modal-dialog.sweet[_ngcontent-gwq-c0] .modal-content[_ngcontent-gwq-c0] .m-warning[_ngcontent-gwq-c0] .micon-dot[_ngcontent-gwq-c0] {
    position: absolute;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    margin-left: -3px;
    left: 50%;
    bottom: 10px;
    background-color: #f0ad2e
}

.modal-dialog.sweet[_ngcontent-gwq-c0] .modal-content[_ngcontent-gwq-c0] .m-error[_ngcontent-gwq-c0] {
    border-color: #f27474
}

.modal-dialog.sweet[_ngcontent-gwq-c0] .modal-content[_ngcontent-gwq-c0] .m-error[_ngcontent-gwq-c0] .x-mark[_ngcontent-gwq-c0] {
    position: relative;
    display: block
}

.modal-dialog.sweet[_ngcontent-gwq-c0] .modal-content[_ngcontent-gwq-c0] .m-error[_ngcontent-gwq-c0] .x-mark[_ngcontent-gwq-c0] .m-line[_ngcontent-gwq-c0] {
    position: absolute;
    height: 5px;
    width: 45px;
    background-color: #f27474;
    display: block;
    top: 28px;
    border-radius: 2px
}

.modal-dialog.sweet[_ngcontent-gwq-c0] .modal-content[_ngcontent-gwq-c0] .m-error[_ngcontent-gwq-c0] .x-mark[_ngcontent-gwq-c0] .m-left[_ngcontent-gwq-c0] {
    transform: rotate(45deg);
    -ms-transform: rotate(45deg)\9;
    left: 8px
}

.modal-dialog.sweet[_ngcontent-gwq-c0] .modal-content[_ngcontent-gwq-c0] .m-error[_ngcontent-gwq-c0] .x-mark[_ngcontent-gwq-c0] .m-right[_ngcontent-gwq-c0] {
    -ms-transform: rotate(-45deg)\9;
    transform: rotate(-45deg);
    right: 8px
}

.modal-dialog.sweet[_ngcontent-gwq-c0] .modal-content[_ngcontent-gwq-c0] .m-success[_ngcontent-gwq-c0] {
    border-color: #a5dc86
}

.modal-dialog.sweet[_ngcontent-gwq-c0] .modal-content[_ngcontent-gwq-c0] .m-success[_ngcontent-gwq-c0]:before {
    border-radius: 120px 0 0 120px;
    top: -17px;
    left: -44px;
    -ms-transform-origin: 60px 60px;
    transform-origin: 60px 60px
}

.modal-dialog.sweet[_ngcontent-gwq-c0] .modal-content[_ngcontent-gwq-c0] .m-success[_ngcontent-gwq-c0]:after {
    border-radius: 0 120px 120px 0;
    top: -23px;
    left: 18px;
    -ms-transform-origin: 0 60px;
    transform-origin: 0 60px
}

.modal-dialog.sweet[_ngcontent-gwq-c0] .modal-content[_ngcontent-gwq-c0] .m-success[_ngcontent-gwq-c0]:after,
.modal-dialog.sweet[_ngcontent-gwq-c0] .modal-content[_ngcontent-gwq-c0] .m-success[_ngcontent-gwq-c0]:before {
    content: '';
    position: absolute;
    width: 62px;
    height: 114px;
    background: #fff;
    -ms-transform: rotate(-44deg);
    transform: rotate(-44deg)
}

.modal-dialog.sweet[_ngcontent-gwq-c0] .modal-content[_ngcontent-gwq-c0] .m-success[_ngcontent-gwq-c0] .m-line[_ngcontent-gwq-c0] {
    height: 5px;
    background-color: #a5dc86;
    display: block;
    border-radius: 2px;
    position: absolute;
    z-index: 2
}

.modal-dialog.sweet[_ngcontent-gwq-c0] .modal-content[_ngcontent-gwq-c0] .m-success[_ngcontent-gwq-c0] .m-long[_ngcontent-gwq-c0] {
    width: 39px;
    left: 16px;
    top: 30px;
    transform: rotate(-45deg);
    -ms-transform: rotate(-45deg)\9
}

.modal-dialog.sweet[_ngcontent-gwq-c0] .modal-content[_ngcontent-gwq-c0] .m-success[_ngcontent-gwq-c0] .m-tip[_ngcontent-gwq-c0] {
    width: 19px;
    left: 8px;
    top: 36px;
    transform: rotate(45deg);
    -ms-transform: rotate(45deg)\9
}

.modal-dialog.sweet[_ngcontent-gwq-c0] .modal-content[_ngcontent-gwq-c0] .m-success[_ngcontent-gwq-c0] .animateSuccessTip[_ngcontent-gwq-c0] {
    -webkit-animation: .7s animateSuccessTip;
    animation: .7s animateSuccessTip
}

.modal-dialog.sweet[_ngcontent-gwq-c0] .modal-content[_ngcontent-gwq-c0] .m-success[_ngcontent-gwq-c0] .animateSuccessLong[_ngcontent-gwq-c0] {
    -webkit-animation: .8s animateSuccessLong;
    animation: .8s animateSuccessLong
}

.modal-dialog.sweet[_ngcontent-gwq-c0] .modal-content[_ngcontent-gwq-c0] .m-success[_ngcontent-gwq-c0] .m-placeholder[_ngcontent-gwq-c0] {
    width: 60px;
    height: 60px;
    border: 4px solid rgba(165, 220, 134, .9);
    border-radius: 50%;
    box-sizing: content-box;
    position: absolute;
    left: -4px;
    top: -4px;
    z-index: 2
}

.modal-dialog.sweet[_ngcontent-gwq-c0] .modal-content[_ngcontent-gwq-c0] .m-success[_ngcontent-gwq-c0] .m-fix[_ngcontent-gwq-c0] {
    width: 5px;
    height: 86px;
    background-color: #fff;
    position: absolute;
    left: 16px;
    top: -3px;
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg)
}

.modal-dialog.sweet[_ngcontent-gwq-c0] .modal-content[_ngcontent-gwq-c0] .m-success.loaded[_ngcontent-gwq-c0]:after {
    -webkit-animation: .7s ease-in rotatePlaceholder;
    animation: .7s ease-in rotatePlaceholder
}

.modal-dialog.sweet[_ngcontent-gwq-c0] .modal-content[_ngcontent-gwq-c0] .m-success.loading[_ngcontent-gwq-c0] .m-placeholder[_ngcontent-gwq-c0] {
    border: 4px solid #a5dc86;
    border-left-color: transparent;
    -webkit-animation: .4s linear infinite rotate;
    animation: .4s linear infinite rotate
}

.modal-dialog.sweet[_ngcontent-gwq-c0] .modal-content[_ngcontent-gwq-c0] .m-success.loading[_ngcontent-gwq-c0] .m-line[_ngcontent-gwq-c0] {
    display: none
}

@-webkit-keyframes animateSuccessTip {

    0%,
    60% {
        width: 0;
        left: 1px;
        top: 28px
    }

    80% {
        width: 17px;
        left: 8px;
        top: 35px
    }

    100% {
        width: 19px;
        left: 8px;
        top: 36px
    }
}

@-webkit-keyframes animateSuccessLong {
    0% {
        width: 0;
        left: 16px;
        top: 39px
    }

    65% {
        width: 0;
        left: 20px;
        top: 39px
    }

    84% {
        width: 25px;
        left: 20px;
        top: 32px
    }

    100% {
        width: 39px;
        left: 16px;
        top: 30px
    }
}

@-webkit-keyframes rotatePlaceholder {
    80% {
        transform: rotate(-390deg);
        -webkit-transform: rotate(-390deg)
    }

    100% {
        transform: rotate(-407deg);
        -webkit-transform: rotate(-407deg)
    }
}

@-webkit-keyframes rotate {
    0% {
        -ms-transform: rotate(0);
        transform: rotate(0)
    }

    100% {
        -ms-transform: rotate(-360deg);
        transform: rotate(-360deg)
    }
}

.modal-dialog.sweet[_ngcontent-gwq-c0] .modal-content[_ngcontent-gwq-c0] .pulseWarning[_ngcontent-gwq-c0] {
    -webkit-animation: .75s infinite alternate pulseWarning;
    animation: .75s infinite alternate pulseWarning
}

.breadcrumb[_ngcontent-gwq-c3] {
    padding: 9px 6px 1px 0;
    margin-bottom: 0;
    list-style: none !important;
    background-color: transparent !important;
    border-radius: 0
}

.breadcrumb[_ngcontent-gwq-c3] li[_ngcontent-gwq-c3] {
    display: inline;
    font-size: 12px
}

.breadcrumb[_ngcontent-gwq-c3] li[_ngcontent-gwq-c3]+li[_ngcontent-gwq-c3]:before {
    font-family: FontAwesome;
    color: #d3d3d3;
    content: "\f105"
}

.breadcrumb[_ngcontent-gwq-c3] li[_ngcontent-gwq-c3] a[_ngcontent-gwq-c3] {
    color: #337ab7;
    text-decoration: none
}

ol.breadcrumb[_ngcontent-gwq-c3] li[_ngcontent-gwq-c3] a[_ngcontent-gwq-c3]:hover {
    color: #337ab7;
    text-decoration: underline
}

.breadcrumb-font[_ngcontent-gwq-c3] {
    font-size: 14px;
    font-family: verdana, "Helvetica Neue", Helvetica, Arial, sans-serif
}

.lang[_ngcontent-gwq-c3] {
    padding: 0;
    float: right;
    cursor: pointer
}

.dropdown[_ngcontent-gwq-c3],
.dropup[_ngcontent-gwq-c3] {
    position: relative
}

.lang[_ngcontent-gwq-c3] .lang-dpdwn[_ngcontent-gwq-c3] {
    right: 0;
    left: auto;
    top: 80%
}

.open[_ngcontent-gwq-c3]>.dropdown-menu[_ngcontent-gwq-c3] {
    display: block
}

dropdown-menu[_ngcontent-gwq-c3] {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    display: none;
    float: left;
    min-width: 160px;
    padding: 5px 0;
    margin: 2px 0 0;
    list-style: none;
    font-size: 14px;
    text-align: left;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, .15);
    border-radius: 0;
    box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
    background-clip: padding-box
}

.lang[_ngcontent-gwq-c3] span[_ngcontent-gwq-c3]:before {
    font: normal normal normal 14px/1 FontAwesome;
    content: "\f0ac";
    margin-right: 5px
}

.noPaddingLR[_ngcontent-gwq-c3] {
    padding-left: 0;
    padding-right: 0;
    margin-left: 12px
}

.marginT10[_ngcontent-gwq-c3] {
    margin-top: 10px;
    float: right !important
}

.table[_ngcontent-gwq-c5] .thead-light[_ngcontent-gwq-c5] th[_ngcontent-gwq-c5] {
    color: #495057;
    background-color: #e9ecef;
    border-color: #dee2e6
}

.amendhead[_ngcontent-gwq-c5] {
    height: 34px;
    padding: 5px;
    width: 265px;
    background-color: #bdd7ee;
    font-weight: 700;
    font-size: 14px;
    border-radius: 4px
}

a.disabledTab[_ngcontent-gwq-c5]:hover {
    cursor: not-allowed !important
}

.disabledTab[_ngcontent-gwq-c5] {
    pointer-events: none !important;
    color: #000
}

.col-md-4[_ngcontent-gwq-c5] {
    text-align: left
}

.accordion[_ngcontent-gwq-c5] {
    background-color: #eee;
    color: #444;
    cursor: pointer;
    padding: 18px;
    width: 100%;
    border: none;
    text-align: left;
    outline: 0;
    font-size: 15px;
    transition: .4s
}

.accordion[_ngcontent-gwq-c5]:hover,
.active[_ngcontent-gwq-c5] {
    background-color: #fff
}

.bordertop[_ngcontent-gwq-c5] {
    border-top: 1px solid #337ab7
}

.border-0[_ngcontent-gwq-c5] {
    border: none
}

.panel[_ngcontent-gwq-c5] {
    padding: 0 18px;
    display: none;
    background-color: #fff;
    overflow: hidden
}

.btn-active[_ngcontent-gwq-c5] {
    color: #fff;
    background-color: #2c4e86
}

.btn-default[_ngcontent-gwq-c5] {
    background-color: #fff !important;
    border: 1px solid #a1a1a1;
    color: #555
}

.btn[_ngcontent-gwq-c5] {
    border-radius: 4px;
    font-size: 14px;
    margin: 10px 5px;
    background-color: #fff;
    padding: 10px 20px !important
}

.btn-collapse[_ngcontent-gwq-c5] {
    border-radius: 0;
    padding: 0;
    font-size: 14px;
    margin: 0;
    background-color: #fff;
    color: #000
}

.btn-primary[_ngcontent-gwq-c5] {
    color: #fff;
    background-color: #2c4e86;
    border-color: #264373
}

.invsumm[_ngcontent-gwq-c5] {
    background-color: #17c4bb;
    padding: 3px 25px 0 0;
    color: #fff
}

.invsumm1[_ngcontent-gwq-c5] {
    background-color: #2c4e86;
    color: #fff;
    font-size: 16px
}

.row[_ngcontent-gwq-c5] {
    margin: 0 !important
}

.red[_ngcontent-gwq-c5] .active[_ngcontent-gwq-c5] a[_ngcontent-gwq-c5],
.red[_ngcontent-gwq-c5] .active[_ngcontent-gwq-c5] a[_ngcontent-gwq-c5]:hover {
    background-color: red
}

.border-rightb[_ngcontent-gwq-c5] {
    border: 1px solid #286090
}

.border-rightg[_ngcontent-gwq-c5] {
    border: 1px solid #449d44
}

.border-righto[_ngcontent-gwq-c5] {
    border: 1px solid #ec9c1f
}

.nav-tabs[_ngcontent-gwq-c5]>li[_ngcontent-gwq-c5]>a[_ngcontent-gwq-c5] {
    margin-right: 0 !important
}

.border-right[_ngcontent-gwq-c5] {
    border-right: 1px solid #337ab7
}

.nav-item[_ngcontent-gwq-c5] {
    cursor: pointer
}

paddingTop10[_ngcontent-gwq-c5] {
    padding-top: 10px !important
}

.nav-pills[_ngcontent-gwq-c5] .active[_ngcontent-gwq-c5] .green[_ngcontent-gwq-c5] {
    background-color: #35be96 !important;
    border-right: 1px solid #35be96 !important
}

.nav-pills[_ngcontent-gwq-c5] .active[_ngcontent-gwq-c5] .red[_ngcontent-gwq-c5] {
    background-color: #db6866 !important;
    border-right: 1px solid #db6866 !important
}

.nav-pills[_ngcontent-gwq-c5] .active[_ngcontent-gwq-c5] .orange[_ngcontent-gwq-c5] {
    background-color: #ec9c1f;
    border-right: 1px solid #ec9c1f !important
}

.thorange[_ngcontent-gwq-c5] {
    background-color: #f8e9d1
}

.thgreen[_ngcontent-gwq-c5] {
    background-color: #c9ece1;
}

.thred[_ngcontent-gwq-c5] {
    background-color: #f2d7d6
}

.row.title[_ngcontent-gwq-c5] {
    margin: 5px 80% 10px 5px
}

.invsumm[_ngcontent-gwq-c5]>.col-xs-12.taxp[_ngcontent-gwq-c5] {
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 18px
}

.pull-left[_ngcontent-gwq-c5] {
    float: left
}

.org[_ngcontent-gwq-c5] {
    background-color: #bdd7ee;
    font-size: 14px;
    color: #000;
    text-align: center
}

.org1[_ngcontent-gwq-c5] {
    background-color: #bdd7ee;
    font-size: 12px;
    color: #000;
    text-align: center
}

.rev[_ngcontent-gwq-c5] {
    color: #000;
    text-align: center
}

.text-right[_ngcontent-gwq-c5] {
    text-align: right
}

.text-left[_ngcontent-gwq-c5] {
    text-align: left
}

.cutoff-tabpane[_ngcontent-gwq-c5] {
    background-color: #fff;
    padding: 15px !important;
    height: auto
}

.table[_ngcontent-gwq-c7] .thead-light[_ngcontent-gwq-c7] th[_ngcontent-gwq-c7] {
    color: #495057;
    background-color: #e9ecef;
    border-color: #dee2e6
}

.amendhead[_ngcontent-gwq-c7] {
    height: 34px;
    padding: 5px;
    width: 265px;
    background-color: #bdd7ee;
    font-weight: 700;
    font-size: 14px;
    border-radius: 4px
}

.btn-help[_ngcontent-gwq-c7] {
    border-radius: 3px;
    padding: 3px 5px;
    font-size: 12px;
    margin: 0;
    background-color: #337ab7 !important;
    border: 1px solid #337ab7;
    color: #fff
}

a.disabled[_ngcontent-gwq-c7]:hover {
    cursor: not-allowed !important;
    opacity: .5 !important;
    text-decoration: none !important
}

a[_ngcontent-gwq-c7]:hover {
    cursor: pointer
}

.thgreen[_ngcontent-gwq-c7] a[_ngcontent-gwq-c7]:hover,
.thred[_ngcontent-gwq-c7] a[_ngcontent-gwq-c7]:hover {
    text-decoration: none;
    cursor: pointer
}

.col-md-4[_ngcontent-gwq-c7] {
    text-align: left
}

.accordion[_ngcontent-gwq-c7] {
    background-color: #eee;
    color: #444;
    cursor: pointer;
    padding: 18px;
    width: 100%;
    border: none;
    text-align: left;
    outline: 0;
    font-size: 15px;
    transition: .4s
}

.accordion[_ngcontent-gwq-c7]:hover,
.active[_ngcontent-gwq-c7] {
    background-color: #fff
}

.bordertop[_ngcontent-gwq-c7] {
    border-top: 1px solid #337ab7
}

.border-0[_ngcontent-gwq-c7] {
    border: none
}

.panel[_ngcontent-gwq-c7] {
    padding: 0 18px;
    display: none;
    background-color: #fff;
    overflow: hidden
}

.btn[_ngcontent-gwq-c7] {
    border-radius: 0;
    padding: 6px 25px;
    font-size: 14px;
    margin: 10px 5px;
    background-color: #fff
}

.btn-collapse[_ngcontent-gwq-c7] {
    border-radius: 0;
    padding: 0;
    font-size: 14px;
    margin: 0;
    background-color: #fff;
    color: #000
}

.btn-primary[_ngcontent-gwq-c7] {
    color: #fff;
    background-color: #2c4e86;
    border-color: #264373
}

.invsumm[_ngcontent-gwq-c7] {
    background-color: #17c4bb;
    padding: 3px 25px 0 0;
    color: #fff
}

.invsumm1[_ngcontent-gwq-c7] {
    background-color: #2c4e86;
    color: #fff;
    font-size: 16px
}

.row[_ngcontent-gwq-c7] {
    margin: 0 !important
}

.red[_ngcontent-gwq-c7] .active[_ngcontent-gwq-c7] a[_ngcontent-gwq-c7],
.red[_ngcontent-gwq-c7] .active[_ngcontent-gwq-c7] a[_ngcontent-gwq-c7]:hover {
    background-color: red
}

.border-rightb[_ngcontent-gwq-c7] {
    border: 1px solid #286090
}

.border-rightg[_ngcontent-gwq-c7] {
    border: 1px solid #449d44
}

.border-righto[_ngcontent-gwq-c7] {
    border: 1px solid #ec9c1f
}

.nav-tabs[_ngcontent-gwq-c7]>li[_ngcontent-gwq-c7]>a[_ngcontent-gwq-c7] {
    margin-right: 0 !important
}

.border-right[_ngcontent-gwq-c7] {
    border-right: 1px solid #337ab7
}

paddingTop10[_ngcontent-gwq-c7] {
    padding-top: 10px !important
}

.nav-pills[_ngcontent-gwq-c7] .active[_ngcontent-gwq-c7] .green[_ngcontent-gwq-c7] {
    background-color: #35be96 !important;
    border-right: 1px solid #35be96 !important
}

.nav-pills[_ngcontent-gwq-c7] .active[_ngcontent-gwq-c7] .red[_ngcontent-gwq-c7] {
    background-color: #db6866 !important;
    border-right: 1px solid #db6866 !important
}

.nav-pills[_ngcontent-gwq-c7] .active[_ngcontent-gwq-c7] .orange[_ngcontent-gwq-c7] {
    background-color: #ec9c1f;
    border-right: 1px solid #ec9c1f !important
}

.thorange[_ngcontent-gwq-c7] {
    background-color: #f8e9d1
}

.thgreen[_ngcontent-gwq-c7] {
    background-color: #c9ece1;
    vertical-align: middle !important;
}

.thred[_ngcontent-gwq-c7] {
    background-color: #f2d7d6
}

.row.title[_ngcontent-gwq-c7] {
    margin: 5px 80% 10px 5px
}

.invsumm[_ngcontent-gwq-c7]>.col-xs-12.taxp[_ngcontent-gwq-c7] {
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 18px
}

.pull-left[_ngcontent-gwq-c7] {
    float: left
}

.org[_ngcontent-gwq-c7] {
    background-color: #bdd7ee;
    font-size: 14px;
    color: #000;
    text-align: center
}

.org1[_ngcontent-gwq-c7] {
    background-color: #bdd7ee;
    font-size: 12px;
    color: #000;
    text-align: center
}

.rev[_ngcontent-gwq-c7] {
    color: #000;
    text-align: center
}

.text-right[_ngcontent-gwq-c7] {
    text-align: right
}

.text-left[_ngcontent-gwq-c7] {
    text-align: left
}

.btn-active[_ngcontent-gwq-c7] {
    color: #fff;
    background-color: #2c4e86
}

.tooltip-example[_ngcontent-gwq-c7] {
    text-align: center;
    padding: 0 50px
}

.tooltip-example[_ngcontent-gwq-c7] [tooltip][_ngcontent-gwq-c7] {
    display: inline-block;
    margin: 50px 20px;
    width: 180px;
    height: 50px;
    border: 1px solid gray;
    border-radius: 5px;
    line-height: 50px;
    text-align: center
}

.mTab {
    background-color: #fff;
    padding: 20px;
    min-height: auto !important;
}

.table[_ngcontent-gwq-c9] .thead-light[_ngcontent-gwq-c9] th[_ngcontent-gwq-c9] {
    color: #495057;
    background-color: #e9ecef;
    border-color: #dee2e6
}

.col-md-4[_ngcontent-gwq-c9] {
    text-align: left
}

.modsearch[_ngcontent-gwq-c9]:focus {
    border-bottom: 1px solid #2c4e86;
    box-shadow: 0 1px 0 0 #2c4e86
}

.table-responsive[_ngcontent-gwq-c9] {
    max-height: 30vh;
    overflow-y: auto
}

.dropdown-item[_ngcontent-gwq-c9] {
    display: inline-block
}

.btn[_ngcontent-gwq-c9],
.btn-custom[_ngcontent-gwq-c9] {
    color: #fff;
    background-color: #337ab7 !important;
    border: 1px solid #337ab7;
    border-radius: 4px !important;
    padding: 10px 20px !important
}

.dropdown[_ngcontent-gwq-c9] .btn-custom1[_ngcontent-gwq-c9] {
    color: #3a3a3a;
    background-color: #add8e6 !important;
    border: 1px solid #add8e6;
    padding: 10px 20px !important
}

.btn-default[_ngcontent-gwq-c9],
.form-inline[_ngcontent-gwq-c9] .btn[_ngcontent-gwq-c9] {
    background-color: #fff !important;
    border: 1px solid #a1a1a1;
    color: #555
}

.btn-custom[_ngcontent-gwq-c9] .active[_ngcontent-gwq-c9],
.btn-custom[_ngcontent-gwq-c9]:hover {
    background-color: #286090
}

.mat-ink-bar[_ngcontent-gwq-c9] {
    background-color: #2665c9
}

.mat-tab-body-content[_ngcontent-gwq-c9] {
    overflow: hidden !important
}

.mat-tab-group[_ngcontent-gwq-c9] {
    font-family: unset
}

.modsearch[_ngcontent-gwq-c9] {
    border-bottom: 1px solid #d3d3d3;
    box-shadow: 0 1px 0 0 #d3d3d3
}

.accordion[_ngcontent-gwq-c9] {
    background-color: #eee;
    color: #444;
    cursor: pointer;
    padding: 18px;
    width: 100%;
    border: none;
    text-align: left;
    outline: 0;
    font-size: 15px;
    transition: .4s
}

.accordion[_ngcontent-gwq-c9]:hover,
.active[_ngcontent-gwq-c9] {
    background-color: #fff
}

.border-right[_ngcontent-gwq-c9] {
    border-right: 1px solid #337ab7;
    border-bottom: 1px solid #337ab7
}

.panel[_ngcontent-gwq-c9] {
    padding: 0 18px;
    display: none;
    background-color: #fff;
    overflow: hidden
}

.btn[_ngcontent-gwq-c9] {
    border-radius: 0;
    padding: 6px 25px;
    font-size: 14px;
    margin: 10px 5px;
    background-color: #fff
}

.btn-help[_ngcontent-gwq-c9] {
    border-radius: 3px;
    padding: 3px 5px;
    font-size: 12px;
    margin: 0;
    background-color: #337ab7 !important;
    border: 1px solid #337ab7;
    color: #fff
}

.btn-collapse[_ngcontent-gwq-c9] {
    border-radius: 0;
    padding: 0;
    font-size: 14px;
    margin: 0;
    background-color: #fff;
    color: #000
}

.btn-primary[_ngcontent-gwq-c9] {
    color: #fff;
    background-color: #2c4e86;
    border-color: #264373
}

.row.title[_ngcontent-gwq-c9] {
    margin: 5px 80% 10px 5px
}

.invsumm[_ngcontent-gwq-c9]>.col-xs-12.taxp[_ngcontent-gwq-c9] {
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 18px
}

.pull-left[_ngcontent-gwq-c9] {
    float: left
}

.org[_ngcontent-gwq-c9] {
    background-color: #bdd7ee;
    font-size: 14px;
    color: #000;
    text-align: center
}

.org1[_ngcontent-gwq-c9] {
    background-color: #bdd7ee;
    font-size: 12px;
    color: #000;
    text-align: center
}

.rev[_ngcontent-gwq-c9] {
    color: #000;
    text-align: center
}

.text-right[_ngcontent-gwq-c9] {
    text-align: right
}

.text-left[_ngcontent-gwq-c9] {
    text-align: left
}

.btn-active[_ngcontent-gwq-c9] {
    color: #fff;
    background-color: #2c4e86
}

.table[_ngcontent-gwq-c10] .thead-light[_ngcontent-gwq-c10] th[_ngcontent-gwq-c10] {
    color: #495057;
    background-color: #e9ecef;
    border-color: #dee2e6
}

.col-md-4[_ngcontent-gwq-c10] {
    text-align: left
}

.modsearch[_ngcontent-gwq-c10]:focus {
    border-bottom: 1px solid #2c4e86;
    box-shadow: 0 1px 0 0 #2c4e86
}

.dropdown-item[_ngcontent-gwq-c10] {
    display: inline-block
}

.btn[_ngcontent-gwq-c10],
.btn-custom[_ngcontent-gwq-c10] {
    color: #fff;
    background-color: #337ab7 !important;
    border: 1px solid #337ab7;
    border-radius: 4px !important;
    padding: 10px 20px !important
}

.dropdown[_ngcontent-gwq-c10] .btn-custom1[_ngcontent-gwq-c10] {
    color: #3a3a3a;
    background-color: #add8e6 !important;
    border: 1px solid #add8e6;
    padding: 10px 20px !important
}

.btn-default[_ngcontent-gwq-c10],
.form-inline[_ngcontent-gwq-c10] .btn[_ngcontent-gwq-c10] {
    background-color: #fff !important;
    border: 1px solid #a1a1a1;
    color: #555
}

.btn-custom[_ngcontent-gwq-c10] .active[_ngcontent-gwq-c10],
.btn-custom[_ngcontent-gwq-c10]:hover {
    background-color: #286090
}

.mat-ink-bar[_ngcontent-gwq-c10] {
    background-color: #2665c9
}

.mat-tab-body-content[_ngcontent-gwq-c10] {
    overflow: hidden !important
}

.mat-tab-group[_ngcontent-gwq-c10] {
    font-family: unset
}

.nav-pills[_ngcontent-gwq-c10] .active[_ngcontent-gwq-c10] {
    background-color: #35be96 !important;
    border-right: 1px solid #35be96 !important
}

.nav-pills[_ngcontent-gwq-c10]>li.active[_ngcontent-gwq-c10]>a[_ngcontent-gwq-c10] {
    background-color: #35be96 !important
}

.modsearch[_ngcontent-gwq-c10] {
    border-bottom: 1px solid #d3d3d3;
    box-shadow: 0 1px 0 0 #d3d3d3
}

.accordion[_ngcontent-gwq-c10] {
    background-color: #eee;
    color: #444;
    cursor: pointer;
    padding: 18px;
    width: 100%;
    border: none;
    text-align: left;
    outline: 0;
    font-size: 15px;
    transition: .4s
}

.accordion[_ngcontent-gwq-c10]:hover,
.active[_ngcontent-gwq-c10] {
    background-color: #fff
}

.border-right[_ngcontent-gwq-c10] {
    border-right: 1px solid #337ab7;
    border-bottom: 1px solid #337ab7
}

.panel[_ngcontent-gwq-c10] {
    padding: 0 18px;
    display: none;
    background-color: #fff;
    overflow: hidden
}

.btn[_ngcontent-gwq-c10] {
    border-radius: 0;
    padding: 6px 25px;
    font-size: 14px;
    margin: 10px 5px;
    background-color: #fff
}

.btn-collapse[_ngcontent-gwq-c10] {
    border-radius: 0;
    padding: 0;
    font-size: 14px;
    margin: 0;
    background-color: #fff;
    color: #000
}

.btn-primary[_ngcontent-gwq-c10] {
    color: #fff;
    background-color: #2c4e86;
    border-color: #264373
}

.invsumm[_ngcontent-gwq-c10] {
    background-color: #17c4bb;
    padding: 3px 25px 0 0;
    color: #fff
}

.invsumm1[_ngcontent-gwq-c10] {
    background-color: #2c4e86;
    color: #fff;
    font-size: 16px
}

.row.title[_ngcontent-gwq-c10] {
    margin: 5px 80% 10px 5px
}

.invsumm[_ngcontent-gwq-c10]>.col-xs-12.taxp[_ngcontent-gwq-c10] {
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 18px
}

.pull-left[_ngcontent-gwq-c10] {
    float: left
}

.org[_ngcontent-gwq-c10] {
    background-color: #bdd7ee;
    font-size: 14px;
    color: #000;
    text-align: center
}

.org1[_ngcontent-gwq-c10] {
    background-color: #bdd7ee;
    font-size: 12px;
    color: #000;
    text-align: center
}

.rev[_ngcontent-gwq-c10] {
    color: #000;
    text-align: center
}

.text-right[_ngcontent-gwq-c10] {
    text-align: right
}

.text-left[_ngcontent-gwq-c10] {
    text-align: left
}

.btn-active[_ngcontent-gwq-c10] {
    color: #fff;
    background-color: #2c4e86
}

#closeFilter[_ngcontent-gwq-c10] {
    color: #c1bab8;
    height: 37px;
    font-size: 26px;
    margin-top: 10px !important
}

#closeFilter[_ngcontent-gwq-c10]:hover {
    content: attr(data-tooltip);
    cursor: pointer;
    color: #000
}

#closeFilter[_ngcontent-gwq-c10]:hover .tooltiptext[_ngcontent-gwq-c10] {
    visibility: visible
}

.rpp[_ngcontent-gwq-c10] {
    width: auto;
    font-size: 20px;
    float: right;
    margin-left: 10px;
    margin-top: 16px;
    color: #c1bab8
}

.rpp[_ngcontent-gwq-c10]:hover {
    content: attr(data-tooltip);
    cursor: pointer;
    color: #000
}

.rpp[_ngcontent-gwq-c10]:hover .tooltiptext[_ngcontent-gwq-c10] {
    visibility: visible
}

#srpp[_ngcontent-gwq-c10] {
    width: auto;
    font-size: 20px;
    float: right;
    margin-left: 10px;
    margin-top: 8px;
    color: #c1bab8
}

#srpp[_ngcontent-gwq-c10]:hover {
    content: attr(data-tooltip);
    cursor: pointer;
    color: #000
}

#srpp[_ngcontent-gwq-c10]:hover .tooltiptext[_ngcontent-gwq-c10] {
    visibility: visible
}

.pillbutton[_ngcontent-gwq-c10] {
    background-color: #ddd;
    border: none;
    color: #000;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    margin: 4px 2px;
    cursor: pointer;
    outline: 0;
    border-radius: 16px
}

.tooltiptext[_ngcontent-gwq-c10] {
    visibility: hidden;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    font-size: 14px;
    background-color: #000;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px;
    position: absolute;
    z-index: 1;
    top: 15px;
    margin-left: 10px
}

#table[_ngcontent-gwq-c10] tbody[_ngcontent-gwq-c10] tr[_ngcontent-gwq-c10] {
    border: hidden
}

.activeSort[_ngcontent-gwq-c10] {
    color: #00f
}

.plabel[_ngcontent-gwq-c10] {
    padding-top: 15px;
    padding-right: 10px
}

.srlabel[_ngcontent-gwq-c10] {
    padding-top: 10px;
    padding-right: 10px
}

.multiselectDrp[_ngcontent-gwq-c10] {
    width: 210px;
    height: 37px;
    float: right;
    margin-left: 0;
    margin-top: 10px
}

@media all and (-ms-high-contrast:none),
(-ms-high-contrast:active) {
    .multiselectDrp[_ngcontent-gwq-c10] {
        width: 150px;
        height: 37px;
        float: right;
        margin-left: 0;
        margin-top: 10px
    }

    .filterHeader[_ngcontent-gwq-c10] {
        width: 30% !important
    }
}

@supports (-moz-appearance:none) {
    .multiselectDrp[_ngcontent-gwq-c10] {
        width: 150px;
        height: 37px;
        float: right;
        margin-left: 0;
        margin-top: 10px
    }
}

.srchTitle[_ngcontent-gwq-c10] {
    visibility: hidden;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    font-size: 14px;
    background-color: #000;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px;
    position: absolute;
    z-index: 1;
    top: 30px;
    left: 0;
    margin-left: 10px
}

.pcal[_ngcontent-gwq-c10]:hover {
    border: .8px solid #000
}

.pcal[_ngcontent-gwq-c10]:focus {
    border-color: #1c87ff;
    box-shadow: none !important
}

.errClass[_ngcontent-gwq-c10] {
    color: red;
    font-size: 12px;
    margin-top: -10px
}

.multiselect-dropdown[_ngcontent-gwq-c12] {
    position: relative;
    width: 100%;
    font-size: inherit;
    font-family: inherit
}

.multiselect-dropdown[_ngcontent-gwq-c12] .dropdown-btn[_ngcontent-gwq-c12] {
    display: inline-block;
    border: 1px solid #adadad;
    width: 100%;
    padding: 6px 12px;
    margin-bottom: 0;
    font-weight: 400;
    line-height: 1.52857143;
    text-align: left;
    vertical-align: middle;
    cursor: pointer;
    background-image: none;
    border-radius: 4px
}

.multiselect-dropdown[_ngcontent-gwq-c12] .dropdown-btn[_ngcontent-gwq-c12] .selected-item[_ngcontent-gwq-c12] {
    border: 1px solid #337ab7;
    margin-right: 4px;
    background: #337ab7;
    padding: 0 5px;
    color: #fff;
    border-radius: 2px;
    float: left
}

.multiselect-dropdown[_ngcontent-gwq-c12] .dropdown-btn[_ngcontent-gwq-c12] .selected-item[_ngcontent-gwq-c12] a[_ngcontent-gwq-c12] {
    text-decoration: none
}

.multiselect-dropdown[_ngcontent-gwq-c12] .dropdown-btn[_ngcontent-gwq-c12] .selected-item[_ngcontent-gwq-c12]:hover {
    box-shadow: 1px 1px #959595
}

.multiselect-dropdown[_ngcontent-gwq-c12] .dropdown-btn[_ngcontent-gwq-c12] .dropdown-down[_ngcontent-gwq-c12] {
    display: inline-block;
    top: 10px;
    width: 0;
    height: 0;
    border-top: 10px solid #adadad;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent
}

.multiselect-dropdown[_ngcontent-gwq-c12] .dropdown-btn[_ngcontent-gwq-c12] .dropdown-up[_ngcontent-gwq-c12] {
    display: inline-block;
    width: 0;
    height: 0;
    border-bottom: 10px solid #adadad;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent
}

.multiselect-dropdown[_ngcontent-gwq-c12] .disabled[_ngcontent-gwq-c12]>span[_ngcontent-gwq-c12] {
    background-color: #eceeef
}

.dropdown-list[_ngcontent-gwq-c12] {
    position: absolute;
    padding-top: 6px;
    width: 100%;
    z-index: 9999;
    border: 1px solid #ccc;
    border-radius: 3px;
    background: #fff;
    margin-top: 10px;
    box-shadow: 0 1px 5px #959595
}

.dropdown-list[_ngcontent-gwq-c12] ul[_ngcontent-gwq-c12] {
    padding: 0;
    list-style: none;
    overflow: auto;
    margin: 0
}

.dropdown-list[_ngcontent-gwq-c12] li[_ngcontent-gwq-c12] {
    padding: 6px 10px;
    cursor: pointer;
    text-align: left
}

.dropdown-list[_ngcontent-gwq-c12] .filter-textbox[_ngcontent-gwq-c12] {
    border-bottom: 1px solid #ccc;
    position: relative;
    padding: 10px
}

.dropdown-list[_ngcontent-gwq-c12] .filter-textbox[_ngcontent-gwq-c12] input[_ngcontent-gwq-c12] {
    border: 0;
    width: 100%;
    padding: 0 0 0 26px
}

.dropdown-list[_ngcontent-gwq-c12] .filter-textbox[_ngcontent-gwq-c12] input[_ngcontent-gwq-c12]:focus {
    outline: 0
}

.multiselect-item-checkbox[_ngcontent-gwq-c12] input[type=checkbox][_ngcontent-gwq-c12] {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px
}

.multiselect-item-checkbox[_ngcontent-gwq-c12] input[type=checkbox][_ngcontent-gwq-c12]:focus+div[_ngcontent-gwq-c12]:before,
.multiselect-item-checkbox[_ngcontent-gwq-c12] input[type=checkbox][_ngcontent-gwq-c12]:hover+div[_ngcontent-gwq-c12]:before {
    border-color: #337ab7;
    background-color: #f2f2f2
}

.multiselect-item-checkbox[_ngcontent-gwq-c12] input[type=checkbox][_ngcontent-gwq-c12]:active+div[_ngcontent-gwq-c12]:before {
    transition-duration: 0s
}

.multiselect-item-checkbox[_ngcontent-gwq-c12] input[type=checkbox][_ngcontent-gwq-c12]+div[_ngcontent-gwq-c12] {
    position: relative;
    padding-left: 2em;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: pointer;
    margin: 0;
    color: #000
}

.multiselect-item-checkbox[_ngcontent-gwq-c12] input[type=checkbox][_ngcontent-gwq-c12]+div[_ngcontent-gwq-c12]:before {
    box-sizing: content-box;
    content: '';
    color: #337ab7;
    position: absolute;
    top: 50%;
    left: 0;
    width: 14px;
    height: 14px;
    margin-top: -9px;
    border: 2px solid #337ab7;
    text-align: center;
    transition: .4s
}

.multiselect-item-checkbox[_ngcontent-gwq-c12] input[type=checkbox][_ngcontent-gwq-c12]+div[_ngcontent-gwq-c12]:after {
    box-sizing: content-box;
    content: '';
    position: absolute;
    -webkit-transform: scale(0);
    transform: scale(0);
    -webkit-transform-origin: 50%;
    transform-origin: 50%;
    transition: transform .2s ease-out, -webkit-transform .2s ease-out;
    background-color: transparent;
    top: 50%;
    left: 4px;
    width: 8px;
    height: 3px;
    margin-top: -4px;
    border-style: solid;
    border-color: #fff;
    border-width: 0 0 3px 3px;
    -o-border-image: none;
    border-image: none;
    -webkit-transform: rotate(-45deg) scale(0);
    transform: rotate(-45deg) scale(0)
}

.multiselect-item-checkbox[_ngcontent-gwq-c12] input[type=checkbox][_ngcontent-gwq-c12]:disabled+div[_ngcontent-gwq-c12]:before {
    border-color: #ccc
}

.multiselect-item-checkbox[_ngcontent-gwq-c12] input[type=checkbox][_ngcontent-gwq-c12]:disabled:focus+div[_ngcontent-gwq-c12]:before .multiselect-item-checkbox[_ngcontent-gwq-c12] input[type=checkbox][_ngcontent-gwq-c12]:disabled:hover+div[_ngcontent-gwq-c12]:before {
    background-color: inherit
}

.multiselect-item-checkbox[_ngcontent-gwq-c12] input[type=checkbox][_ngcontent-gwq-c12]:disabled:checked+div[_ngcontent-gwq-c12]:before {
    background-color: #ccc
}

.multiselect-item-checkbox[_ngcontent-gwq-c12] input[type=checkbox][_ngcontent-gwq-c12]:checked+div[_ngcontent-gwq-c12]:after {
    content: '';
    transition: transform .2s ease-out, -webkit-transform .2s ease-out;
    -webkit-transform: rotate(-45deg) scale(1);
    transform: rotate(-45deg) scale(1)
}

.multiselect-item-checkbox[_ngcontent-gwq-c12] input[type=checkbox][_ngcontent-gwq-c12]:checked+div[_ngcontent-gwq-c12]:before {
    -webkit-animation: .2s ease-in borderscale;
    animation: .2s ease-in borderscale;
    background: #337ab7
}

@-webkit-keyframes borderscale {
    50% {
        box-shadow: 0 0 0 2px #337ab7
    }
}

@keyframes borderscale {
    50% {
        box-shadow: 0 0 0 2px #337ab7
    }
}

.ngx-pagination {
    margin-left: 0;
    margin-bottom: 1rem;
}

.ngx-pagination::before,
.ngx-pagination::after {
    content: ' ';
    display: table;
}

.ngx-pagination::after {
    clear: both;
}

.ngx-pagination li {
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    margin-right: 0.0625rem;
    border-radius: 0;
}

.ngx-pagination li {
    display: inline-block;
}

.ngx-pagination a,
.ngx-pagination button {
    color: #0a0a0a;
    display: block;
    padding: 0.1875rem 0.625rem;
    border-radius: 0;
}

.ngx-pagination a:hover,
.ngx-pagination button:hover {
    background: #e6e6e6;
}

.ngx-pagination .current {
    padding: 0.1875rem 0.625rem;
    background: #2199e8;
    color: #fefefe;
    cursor: default;
}

.ngx-pagination .disabled {
    padding: 0.1875rem 0.625rem;
    color: #cacaca;
    cursor: default;
}

.ngx-pagination .disabled:hover {
    background: transparent;
}

.ngx-pagination a,
.ngx-pagination button {
    cursor: pointer;
}

.ngx-pagination .pagination-previous a::before,
.ngx-pagination .pagination-previous.disabled::before {
    content: '«';
    display: inline-block;
    margin-right: 0.5rem;
}

.ngx-pagination .pagination-next a::after,
.ngx-pagination .pagination-next.disabled::after {
    content: '»';
    display: inline-block;
    margin-left: 0.5rem;
}

.ngx-pagination .show-for-sr {
    position: absolute !important;
    width: 1px;
    height: 1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
}

.ngx-pagination .small-screen {
    display: none;
}

@media screen and (max-width: 601px) {
    .ngx-pagination.responsive .small-screen {
        display: inline-block;
    }

    .ngx-pagination.responsive li:not(.small-screen):not(.pagination-previous):not(.pagination-next) {
        display: none;
    }
}