.fill-success .overlay {
    background-color: #c9ece1 !important;
    color: #3c763d;
    border-color: #3c763d;
    font-size: 75%;
}

.overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;

    overflow: hidden;
    width: 0;
    height: 100%;
    transition: .5s ease;

}

.column:hover .overlay {
    width: 100%;

}

.fill-danger .overlay {
    background-color: #f2dede;
    color: #a94442;
    border-color: #a94442;
    font-size: 75%;
}

.fill-warning .overlay {
    background-color: #fcf8e3;
    color: #8a6d3b;
    border-color: #8a6d3b;
    font-size: 75%;
}

.fill-danger {
    background-color: #db6866 !important;
    border: 1px solid #a94442 !important;
    color: white !important;
    text-align: center;
}


.fill-success {
    background-color: #35be96 !important;
    border: 1px solid #3c763d !important;
    color: white !important;
    text-align: center;
}

.fill-warning {
    background-color: #ec9d09 !important;
    border: 1px solid #8a6d3b !important;
    color: white !important;
    text-align: center;
}

.rtnflng {
    margin-top: -3.7%;
    margin-left: 10%;
}

.rtnchnge {
    margin-top: -3.7%;
    margin-left: 45%;
}

.tablehdr {
    margin-left: 10%;
}

.spnsze {
    font-size: large;
    font-weight: bold;
}

.formnames {
    border: 1px solid #2c4e86;
    color: #fff;
    background-color: #2c4e86 !important;
    border-bottom: 1px solid white;
    vertical-align: middle !important;
}

.text {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    white-space: nowrap;
}

.mar2 {
    padding-top: 4px;
}

.styled-table {
    border-collapse: collapse;
    margin: 5px 0;
    font-size: 0.9em;
    font-family: sans-serif;

    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

.table-quarter th {
    background-color: #2c4e86;
    color: white;

}

.column {
    float: left;
    width: 100%;
    position: relative;
}

@media screen and (max-width: 600px) {
    .column {
        width: 100%;
        display: block;
    }
}

.width100 {
    width: 100px;
}

.link:hover {
    color: lightblue;
    text-decoration: underline;
}

.myDiv:hover {
    transform: scale(1.2);
}

.flip-card:hover .flip-card-inner {
    transform: rotateY(180deg);
}

.fill-warning:hover {
    background-color: #fcf8e3 !important;
    border: 1px solid #fcf8e3 !important;
    color: #8a6d3b !important;
}

.fill-danger:hover {
    background-color: #f2dede !important;
    border: 1px solid #f2dede !important;
    color: #a94442 !important;
}

.fill-success:hover {
    background-color: #c9ece1 !important;
    border: 1px solid #c9ece1 !important;
    color: #3c763d !important;
}

th {
    text-align: center !important;
}

.fil-disabled {
    background-color: #ddd !important;
    border: 1px solid darkslategrey !important;
    color: black !important;
    text-align: center;
}


.offline_btn {
    padding-left: 15px;
    padding-right: 15px;
}

.mark {
    color: #14385D;
}

.khakhi {
    color: khaki;
}

.content-pane-returns {
    background-color: white;
    padding: 0px 15px 30px 15px;
}

.infoalign {

    margin-left: 1.2%;
    margin-right: 1%;
}

.retpad {
    padding-bottom: 195px;
}

.srchbtn {
    margin-top: 25px;
}

.srchbtn1 {
    margin-top: 65px;
}

.gtsave {
    width: 20%;
}

.disableTile {
    position: absolute;
    background: #eee;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0.5;
    z-index: 100;
    margin: 0 15px;
    cursor: pointer;
}

.whiteside {
    background-color: #FFFAF0;
    padding: 10px;
    color: black;
}

.card .hd {
    height: 113px;
}

.smallbutton {
    padding: 5px 10px;
    font-size: 14px;
    line-height: 1.5;
    border-radius: 0px;
}

.succ {
    font-size: 12px;
    color: green;
}

.input_mar {
    margin-left: 25%;
}

.label_mar {
    margin-left: 5%;
}

.alert_msg {
    margin-left: 5%;
    text-align: center;
    width: 90%;
    margin-top: 2%;
}

.tilwid {
    width: 85%;
}

.cardht {
    min-height: 155px !important;
}

.impcol {
    color: red;
}

.spanBind {
    color: black !important;
    padding-left: 0px !important;
    font-weight: 100 !important;
}

.card .hd {
    /* border-top-left-radius: 1rem;
    border-top-right-radius: 1rem; */
    height: 64px;
}

.btn-lg:focus,
.btn-lg.focus,
.btn-lg:active:focus,
.btn-lg:active.focus,
.btn-lg.active:focus,
.btn-lg.active.focus {
    outline: 0px;
    outline-offset: 0px;
}

b {
    color: #337ab7;
}

/* a {
    color: black;
} */
.newcard {
    text-align: center;
    box-shadow: 4px 4px 10px rgb(153, 149, 149);
    border-bottom-left-radius: 0.5em;
    border-bottom-right-radius: 0.5em;
    background-color: white;
    font-size: large;
    font-weight: bold;
    border: 1px solid grey;
    /* height: 4.5em; */
    padding-left: 0.2em;
    padding-right: 0.2em;
    padding-top: 1em;
    padding-bottom: 1em;
    background-image: linear-gradient(#f1f4ff, white);
}

.headcard {
    text-align: left;
    box-shadow: 4px 4px 10px rgb(153, 149, 149);
    border-top-left-radius: 0.5em;
    border-top-right-radius: 0.5em;
    background-color: #2c4e86;
    font-size: 1.09em;
    font-weight: bold;
    height: 4em;
    padding-left: 0.2em;
    padding-right: 0.2em;
    color: white;
    padding-top: 0.5em;
    margin-top: 0.7em;
    /* background-image: linear-gradient(#f1f4ff, white); */
}

.errorHeadCard {
    background-color: rgb(170, 4, 4);
}

.processed {
    color: green;
    display: inline-block;
}

.error {
    color: red;
    display: inline-block;
}

.pending {
    color: rgb(77, 77, 77);
    display: inline-block;
}

.smallicon {
    height: 1.2em;
    width: 1.2em;
}

.bigicon {
    height: 1.5em;
    width: 1.5em;
}