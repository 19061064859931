.printDoc {
    font-family: 'Open Sans', sans-serif;
    width: '595pt';
    font-size: 14px;
    margin-left: auto;
    margin-right: auto;
    /* border: 1px solid #333; */
    background: #fff;
    margin-top: '20px';
    margin-bottom: '0px';
    padding: 0;
    padding-bottom: 0;
    padding-left: '5pt';
    padding-right: '5pt';
}

.liA {
    display: list-item;
    color: black;
    font-family: "Trebuchet MS", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 9pt;
    margin: 4pt 0 0 0;
}

.s1 {
    color: black;
    font-family: "Trebuchet MS", sans-serif;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 12pt;
    font-weight: bold;
    margin: 0;
}

.s2 {
    color: black;
    font-family: "Trebuchet MS", sans-serif;
    font-style: italic;
    font-weight: normal;
    text-decoration: none;
    font-size: 8.5pt;
}

.s3 {
    color: black;
    font-family: "Trebuchet MS", sans-serif;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 8pt;
}

.s4 {
    color: black;
    font-family: "Trebuchet MS", sans-serif;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 9pt;
    margin: 0;
}

.s5 {
    color: black;
    font-family: "Trebuchet MS", sans-serif;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 8pt;
}

.s7 {
    color: black;
    font-family: "Trebuchet MS", sans-serif;
    font-style: normal;
    font-weight: 600;
    text-decoration: none;
    font-size: 9pt;
}

.s7sm {
    color: black;
    font-family: "Trebuchet MS", sans-serif;
    font-style: normal;
    font-weight: 600;
    text-decoration: none;
    font-size: 8pt;
}

.s8 {
    color: #00F;
    font-family: "Trebuchet MS", sans-serif;
    font-style: normal;
    font-weight: normal;
    text-decoration: underline;
    font-size: 9pt;
}

.s16 {
    color: #00F;
    font-family: "Trebuchet MS", sans-serif;
    font-style: normal;
    font-weight: normal;
    text-decoration: underline;
    font-size: 8pt;
}

.s19 {
    color: black;
    font-family: "Times New Roman", serif;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 9pt;
}

.s20 {
    color: black;
    font-family: "Trebuchet MS", sans-serif;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 9pt;
}