.printDoc {
    font-family: 'Open Sans', sans-serif;
    width: 1080px;
    font-size: 14px;
    margin-left: auto;
    margin-right: auto;
    /* border: 1px solid #333; */
    background: #fff;
    margin-top: 20px;
    margin-bottom: 0;
    padding: 0;
}

.printDoc * {
    margin: 0;
    padding: 0;
    text-indent: 0;
}

.s1 {
    color: black;
    font-family: "Trebuchet MS", sans-serif;
    font-style: normal;
    font-weight: bold;
    text-decoration: none;
    font-size: 18pt;
}

.s2 {
    color: black;
    font-family: "Trebuchet MS", sans-serif;
    font-style: normal;
    font-weight: bold;
    text-decoration: none;
    font-size: 12pt;
}

.s3 {
    color: black;
    font-family: "Trebuchet MS", sans-serif;
    font-style: italic;
    font-weight: 400;
    text-decoration: none;
    font-size: 11pt;
}

.s4 {
    color: black;
    font-family: "Trebuchet MS", sans-serif;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 10pt;
}

.s8 {
    color: black;
    font-family: "Trebuchet MS", sans-serif;
    font-style: normal;
    font-weight: bold;
    text-decoration: none;
    font-size: 14pt;
}

.s9 {
    color: black;
    font-family: "Trebuchet MS", sans-serif;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 10pt;
}

.s10 {
    color: #FFF;
    font-family: "Trebuchet MS", sans-serif;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 10pt;
}

.l1 {
    padding-left: 0pt;
    counter-reset: c1 4;
}

.l1>li>*:first-child:before {
    counter-increment: c1;
    content: counter(c1, decimal)". ";
    color: black;
    font-family: "Trebuchet MS", sans-serif;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 14pt;
}

.l1>li:first-child>*:first-child:before {
    counter-increment: c1 0;
}

.l2 {
    padding-left: 0pt;
    counter-reset: c2 1;
}

.l2>li>*:first-child:before {
    counter-increment: c2;
    content: counter(c2, decimal)". ";
    color: black;
    font-family: "Trebuchet MS", sans-serif;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 11pt;
}

.l2>li:first-child>*:first-child:before {
    counter-increment: c2 0;
}

.l3 {
    padding-left: 0pt;
    counter-reset: c3 1;
}

.l3>li>*:first-child:before {
    counter-increment: c3;
    content: counter(c3, lower-latin)". ";
    color: black;
    font-family: "Trebuchet MS", sans-serif;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 11pt;
}

.l3>li:first-child>*:first-child:before {
    counter-increment: c3 0;
}

.l4 {
    padding-left: 0pt;
    counter-reset: d1 1;
}

.l4>li>*:first-child:before {
    counter-increment: d1;
    content: counter(d1, lower-latin)". ";
    color: black;
    font-family: "Trebuchet MS", sans-serif;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 10pt;
}

.l4>li:first-child>*:first-child:before {
    counter-increment: d1 0;
}

.l5 {
    padding-left: 0pt;
    counter-reset: d2 1;
}

.l5>li>*:first-child:before {
    counter-increment: d2;
    content: counter(d2, lower-roman)". ";
    color: black;
    font-family: "Trebuchet MS", sans-serif;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 10pt;
}

.l5>li:first-child>*:first-child:before {
    counter-increment: d2 0;
}

li {
    display: block;
}

.l6 {
    padding-left: 0pt;
    counter-reset: e1 4;
}

.l6>li>*:first-child:before {
    counter-increment: e1;
    content: counter(e1, decimal)". ";
    color: black;
    font-family: "Trebuchet MS", sans-serif;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 11pt;
}

.l6>li:first-child>*:first-child:before {
    counter-increment: e1 0;
}

.l7 {
    padding-left: 0pt;
    counter-reset: e2 1;
}

.l7>li>*:first-child:before {
    counter-increment: e2;
    content: counter(e2, upper-latin)". ";
    color: black;
    font-family: "Trebuchet MS", sans-serif;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 11pt;
}

.l7>li:first-child>*:first-child:before {
    counter-increment: e2 0;
}

li {
    display: block;
}

.l8 {
    padding-left: 0pt;
    counter-reset: f1 1;
}

.l8>li>*:first-child:before {
    counter-increment: f1;
    content: counter(f1, lower-latin)". ";
    color: black;
    font-family: "Trebuchet MS", sans-serif;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 11pt;
}

.l8>li:first-child>*:first-child:before {
    counter-increment: f1 0;
}

li {
    display: block;
}

.l9 {
    padding-left: 0pt;
    counter-reset: g1 8;
}

.l9>li>*:first-child:before {
    counter-increment: g1;
    content: counter(g1, decimal)". ";
    color: black;
    font-family: "Trebuchet MS", sans-serif;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 11pt;
}

.l9>li:first-child>*:first-child:before {
    counter-increment: g1 0;
}

li {
    display: block;
}

.l10 {
    padding-left: 0pt;
    counter-reset: h1 1;
}

.l10>li>*:first-child:before {
    counter-increment: h1;
    content: counter(h1, lower-roman)". ";
    color: black;
    font-family: "Trebuchet MS", sans-serif;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 10pt;
}

.l10>li:first-child>*:first-child:before {
    counter-increment: h1 0;
}

li {
    display: block;
}

.l11 {
    padding-left: 0pt;
    counter-reset: i1 1;
}

.l11>li>*:first-child:before {
    counter-increment: i1;
    content: counter(i1, lower-roman)". ";
    color: black;
    font-family: "Trebuchet MS", sans-serif;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 10pt;
}

.l11>li:first-child>*:first-child:before {
    counter-increment: i1 0;
}

li {
    display: block;
}

.l12 {
    padding-left: 0pt;
    counter-reset: j1 2;
}

.l12>li>*:first-child:before {
    counter-increment: j1;
    content: counter(j1, lower-roman)". ";
    color: black;
    font-family: "Trebuchet MS", sans-serif;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 10pt;
}

.l12>li:first-child>*:first-child:before {
    counter-increment: j1 0;
}

li {
    display: block;
}

.l13 {
    padding-left: 0pt;
    counter-reset: k1 1;
}

.l13>li>*:first-child:before {
    counter-increment: k1;
    content: counter(k1, lower-roman)". ";
    color: black;
    font-family: "Trebuchet MS", sans-serif;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 10pt;
}

.l13>li:first-child>*:first-child:before {
    counter-increment: k1 0;
}

li {
    display: block;
}

.l14 {
    padding-left: 0pt;
    counter-reset: l1 1;
}

.l14>li>*:first-child:before {
    counter-increment: l1;
    content: counter(l1, lower-roman)". ";
    color: black;
    font-family: "Trebuchet MS", sans-serif;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 10pt;
}

.l14>li:first-child>*:first-child:before {
    counter-increment: l1 0;
}

li {
    display: block;
}

.l15 {
    padding-left: 0pt;
    counter-reset: m1 1;
}

.l15>li>*:first-child:before {
    counter-increment: m1;
    content: counter(m1, lower-roman)". ";
    color: black;
    font-family: "Trebuchet MS", sans-serif;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 10pt;
}

.l15>li:first-child>*:first-child:before {
    counter-increment: m1 0;
}

li {
    display: block;
}

.l16 {
    padding-left: 0pt;
    counter-reset: n1 1;
}

.l16>li>*:first-child:before {
    counter-increment: n1;
    content: counter(n1, lower-roman)". ";
    color: black;
    font-family: "Trebuchet MS", sans-serif;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 10pt;
}

.l16>li:first-child>*:first-child:before {
    counter-increment: n1 0;
}

li {
    display: block;
}

.l17 {
    padding-left: 0pt;
    counter-reset: o1 1;
}

.l17>li>*:first-child:before {
    counter-increment: o1;
    content: counter(o1, lower-roman)". ";
    color: black;
    font-family: "Trebuchet MS", sans-serif;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 10pt;
}

.l17>li:first-child>*:first-child:before {
    counter-increment: o1 0;
}

li {
    display: block;
}

.l18 {
    padding-left: 0pt;
    counter-reset: p1 1;
}

.l18>li>*:first-child:before {
    counter-increment: p1;
    content: counter(p1, lower-roman)". ";
    color: black;
    font-family: "Trebuchet MS", sans-serif;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 10pt;
}

.l18>li:first-child>*:first-child:before {
    counter-increment: p1 0;
}

li {
    display: block;
}

.l19 {
    padding-left: 0pt;
    counter-reset: q1 1;
}

.l19>li>*:first-child:before {
    counter-increment: q1;
    content: counter(q1, lower-roman)". ";
    color: black;
    font-family: "Trebuchet MS", sans-serif;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 10pt;
}

.l19>li:first-child>*:first-child:before {
    counter-increment: q1 0;
}